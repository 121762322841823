import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom';

import {Event} from './routes/guest/event';

import './index.css';
// import {Login} from "@/routes/user/auth/login";
// import {Register} from "@/routes/user/auth/register";
// import {AuthLayout} from "@/routes/user/auth";
// import {ResetPassword} from "@/routes/user/auth/password/reset";
// import {SendLinkResetPassword} from "@/routes/user/auth/password/email";
import {Provider} from "react-redux";
import store from './store'
import {GuestLayout} from "@/routes/guest";
import {Checkout} from "@/routes/guest/event/checkout";
import {Ticket} from "@/routes/guest/ticket";
import {RetrieveTicket} from "@/routes/guest/event/retrieve/ticket";
import NotFound from "@/routes/error/404";

const router = createBrowserRouter([
    /*{
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'reset-password/send-email',
          element: <SendLinkResetPassword />,
        },
        {
          path: 'register',
          element: <Register />,
        },
      ]
    },*/
    {
        path: '/',
        loader: () => {
            return redirect(process.env.REACT_APP_LANDING_URL + '/event');
        },
    },
    {
        path: 'tickets',
        element: <GuestLayout/>,
        children: [
            {
                path: ':slug',
                element: <Ticket/>,
            },
        ]
    },
    {
        element: <GuestLayout/>,
        children: [
            {
                path: ':slug',
                element: <Event/>,
            },
            {
                path: ':slug/checkout',
                element: <Checkout/>,
            },
            {
                path: ':slug/retrieve-ticket',
                element: <RetrieveTicket/>,
            }
        ]
    },
    {
        path: '*',
        element: <NotFound/>
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>
);

// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
